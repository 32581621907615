<template>
  <loading-dialog :loading="$apollo.loading">
    <v-card flat class="pt-4">
      <v-alert class="ma-4" type="info" dense outlined>Para mais informações sobre ativação de Cashback, checar documentação.</v-alert>
      <v-btn color="primary" text outlined class="mx-4 my-2" @click="openOutlineDocs"
        ><v-icon class="mr-2">mdi-label-outline</v-icon> Documentação Cashback</v-btn
      >

      <v-container fluid class="px-6">
        <v-row no-gutters class="mb-4">
          <v-icon class="mr-2" x-small :color="cashbackModule ? 'success' : 'error'">mdi-brightness-1</v-icon
          >{{ `Módulo Cashback ${cashbackModule ? 'ativo' : 'inativo'}` }}
        </v-row>
        <span>Ativar Cashback:</span>
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" style="width:min-content;" v-on="on">
              <v-switch v-model="cashback" :disabled="!editable" class="pt-4" value />
            </div>
          </template>
          <span>Ativa o Cashback no canal e nos modulos do cliente</span>
        </v-tooltip>
      </v-container>
      <btn-card-actions class="pb-4" :editable.sync="editable" :saving="saving" @save="saveEditCashback" @cancel="cancelAndRestore" />
    </v-card>

    <v-dialog v-model="appDialog" max-width="500" persistent transition="dialog-bottom-transition" @click:outside="appDialog = false">
      <v-card class="pa-4">
        <v-card-title><v-icon class="mx-auto" x-large>mdi-alert-circle</v-icon></v-card-title>
        <v-card-text class="text-center">Deseja ser redirecionado para configuração de Cashback no Aplicativo?</v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="primary darken-1" :to="`/accounts/${this.accountId}/app-config`" outlined>Redirecionar</v-btn>
          <v-btn color="primary darken-1" outlined @click="appDialog = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </loading-dialog>
</template>

<script>
import { QUERY_GET_ACCOUNT, MUTATION_ACTIVATE_ACCOUNT_CASHBACK } from '@/modules/accounts/graphql'

export default {
  components: {
    LoadingDialog: () => import('@/components/base/LoadingDialog'),
    BtnCardActions: () => import('@/components/base/BtnCardActions')
  },
  data: () => ({
    accountId: '',
    editable: false,
    appDialog: false,
    cashback: null,
    cashbackModule: null,
    saving: false
  }),
  apollo: {
    adminAccount: {
      query: QUERY_GET_ACCOUNT,
      fetchPolicy: 'network-only',
      variables() {
        return { _id: this.accountId }
      },
      skip() {
        return !this.accountId
      },
      update({ adminAccount }) {
        if (!adminAccount) return {}

        this.cashback = adminAccount?.channels?.cashback?.active || false
        this.cashbackModule = adminAccount?.modules?.cashback || false
      }
    }
  },
  mounted() {
    this.accountId = this.$route.params.id
  },
  methods: {
    async saveEditCashback() {
      try {
        this.saving = true
        await this.$apollo.mutate({
          mutation: MUTATION_ACTIVATE_ACCOUNT_CASHBACK,
          variables: {
            id: this.accountId,
            active: this.cashback
          }
        })
        this.cancelAndRestore()
        this.$snackbar({ message: 'Cashback ativado com sucesso', snackbarColor: '#2E7D32' })
      } catch (err) {
        console.log(err.message)
        this.$snackbar({ message: 'Falha ao ativar cashback', snackbarColor: '#F44336' })
      }
      this.saving = false
      if (this.cashback) this.appDialog = true
    },
    cancelAndRestore() {
      this.editable = false
      this.$apollo.queries.adminAccount.refetch()
    },
    openOutlineDocs() {
      return window.open('https://mercafacil.getoutline.com/doc/ativacao-cashback-mHKk4PP4jT', '_blank')
    }
  }
}
</script>
